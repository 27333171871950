<template>
  <div class="uk-margin-top uk-margin-bottom">
    <div class="uk-card uk-card-default uk-padding-small kandang-card">
      <FilterUser />
      <TableUser />
    </div>
  </div>
</template>
<script>
import FilterUser from './filter'
import TableUser from './table'

export default {
  components: {
    FilterUser,
    TableUser
  }
}
</script>

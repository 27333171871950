<template>
  <MainTable :table-props="tableProps">
    <thead class="thead-table-paranje">
      <tr>
        <th class="uk-table-small">
          <span class="uk-text-bold">Kode barang</span>
        </th>
        <th class="uk-table-small">
          <span class="uk-text-bold">Jenis Pakan</span>
        </th>
        <th class="uk-table-small">
          <span class="uk-text-bold">Nama Barang</span>
        </th>
        <th class="uk-table-small">
          <span class="uk-text-bold">Terakhir Update</span>
        </th>
      </tr>
    </thead>
    <template v-if="!is_loading">
      <tbody v-if="feedCategorys.meta.total_count>0">
        <tr
          v-for="(feed, i) in feedCategorys.data"
          :key="i"
        >
          <td>{{ feed.code ? feed.code : '-' }}</td>
          <td>{{ feed.item_category_name ? feed.item_category_name : '-' }}</td>
          <td>{{ feed.name ? feed.name : '-' }}</td>
          <td>{{ formatDate(feed.updated_at) }}</td>
        </tr>
      </tbody>
      <empty-table
        v-else
        :colspan="7"
      />
    </template>
    <template v-else>
      <loading-table :colspan="7" />
    </template>
  </MainTable>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex'
import MainTable from '@/components/globals/table'
import EmptyTable from '@/components/globals/table/EmptyTable'
import LoadingTable from '@/components/globals/table/LoadingTable'
import { dateUtcParanjeString } from '@/utils/formater'

export default {
  components: {
    EmptyTable,
    LoadingTable,
    MainTable
  },
  data() {
    return {
      is_loading: true,
      tableProps: {
        total_count: 0
      }
    }
  },
  computed : {
    ...mapGetters({
      feedCategorys: 'feedCategory/feedCategorys',
      meta: 'feedCategory/meta'
    })
  },
  watch: {
    feedCategorys() {
      if (this.feedCategorys) {
        this.tableProps ={
          ...this.meta,
          meta: this.meta,
          setMeta: this.setMeta,
          total_count: this.feedCategorys.meta.total_count
        }
      }
    },
    async meta() {
      this.is_loading = true
      await this.getFeedCategory({...this.meta, by_item_category: 'feed'})
      this.is_loading = false
    }
  },
  async mounted() {
    await this.getFeedCategory({...this.meta, by_item_category: 'feed'})
    this.is_loading = false
  },
  methods: {
    formatDate(date) {
      return dateUtcParanjeString(date)
    },
    ...mapActions({
      getFeedCategory: 'feedCategory/getFeedCategory'
    }),
    ...mapMutations({
      setMeta: 'feedCategory/SET_META'
    })
  }
}
</script>

<template>
  <div class="uk-margin-top">
    <div class="uk-overflow-auto border-table">
      <table class="uk-table uk-table-striped uk-table-hover uk-table-divider">
        <slot />
      </table>
    </div>
    <template v-if="tableProps.total_count > 0">
      <pagination
        :total-data="tableProps.total_count"
        :change-limit="changeLimit"
        :change-page="changePage"
        :current-page="tableProps.page"
        :current-limit="tableProps.limit"
      />
    </template>
  </div>
</template>

<script>
import Pagination from '@/components/globals/Pagination'

export default {
  components: {
    Pagination
  },
  props: {
    tableProps: {
      required: true,
      type: Object
    }
  },
  methods: {
    changeLimit(e) {
      this.tableProps.setMeta({
        ...this.tableProps.meta,
        page: 1,
        limit: e.target.value
      })
    },
    changePage(value) {
      this.tableProps.setMeta({
        ...this.tableProps.meta,
        page: value
      })
    }
  }
}
</script>
